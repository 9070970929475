import { PropsWithChildren, useEffect, useState } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { TbCalendarEvent } from 'react-icons/tb'
import { pushGtmEvent } from 'helpers/gtm'
import { calendlyWidgetUrl, calendlyPopupUrl, LINKS } from 'routes/constants'
import { useTrackingContext } from 'routes/components/Layout'
import { Button, Link } from 'routes/components/Shared'
import Popup from 'routes/components/Shared/Popup/Popup'
import { CalendlyProvider, useCalendly } from './provider'
import { CalendlyEventData } from './types'
import {
  fetchCalendlyData,
  isCalendlyEvent,
  isCalendlyEventScheduled,
} from './helpers'
export { CalendlyProvider }
import clsx from 'clsx'
import classes from './Calendly.module.scss'

export function BookCalendlyIframe({ className }: { className?: string }) {
  const { addTrackingToUrl } = useTrackingContext()
  const { setEventData } = useCalendly()

  useEffect(() => {
    window.addEventListener('message', trackCalendlyEventData)
    return () => window.removeEventListener('message', trackCalendlyEventData)
  }, [])

  async function trackCalendlyEventData(e: MessageEvent<CalendlyEventData>) {
    if (!isCalendlyEvent(e) || !isCalendlyEventScheduled(e)) {
      return
    }

    pushGtmEvent('calendly_event_scheduled')
    const payloadEventUri = e?.data?.payload?.event?.uri
    const payloadInviteeUri = e?.data?.payload?.invitee?.uri

    if (payloadEventUri) {
      const event = await fetchCalendlyData(payloadEventUri)
      setEventData(event)
    }

    if (payloadInviteeUri) {
      const user = await fetchCalendlyData(payloadInviteeUri)
      pushGtmEvent('user_identify', {
        userName: user.name,
        userEmail: user.email,
      })
    }
  }

  return (
    <iframe
      src={addTrackingToUrl(calendlyPopupUrl)}
      allowFullScreen
      className={className}
    ></iframe>
  )
}

export function BookCalendlyLink({
  children,
  className,
  type,
  isReversed,
  showIcon = true,
}: PropsWithChildren<{
  className: string
  type: string
  isReversed?: boolean
  showIcon?: boolean
}>) {
  return (
    <Link
      className={clsx(classes.button, className)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type={type as any}
      href={LINKS.BOOK_A_DEMO}
    >
      <span
        className={clsx(
          classes.button_caption,
          isReversed && classes.button_caption__reversed,
        )}
      >
        {children}
      </span>
      {showIcon && (
        <TbCalendarEvent
          className={clsx(
            classes.button_icon,
            isReversed && classes.button_icon__reversed,
          )}
        />
      )}
    </Link>
  )
}

export function BookCalendlyWidget() {
  const { addTrackingToUrl } = useTrackingContext()

  const defaultWidgetHeight = '700px'
  const [widgetHeight, setWidgetHeight] = useState<string>(defaultWidgetHeight) // set default height

  const widgetStyles = {
    width: '100%',
    height: widgetHeight,
    minHeight: defaultWidgetHeight,
    overflow: 'hidden',
  }

  useCalendlyEventListener({
    onPageHeightResize: (event) => {
      setWidgetHeight(event.data?.payload.height) // Update height dynamically
    },
  })

  const options = {
    backgroundColor: '080a1e',
    textColor: 'ffffff',
    primaryColor: '806ef6',
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
  }

  return (
    <InlineWidget
      url={addTrackingToUrl(calendlyWidgetUrl)}
      pageSettings={options}
      styles={widgetStyles}
    />
  )
}

export function RequestPoCCalendlyPopup() {
  const { showPopup, togglePopup, setEventData } = useCalendly()

  function handleClose() {
    // Clear event data on close
    setEventData(null)
    togglePopup()
  }

  return (
    <Popup onClose={handleClose} open={showPopup} className={classes.container}>
      <RequestPoCCalendlyWidget />
    </Popup>
  )
}

export function RequestPoCCalendlyButton({
  children,
  className,
  category,
  isReversed,
  showIcon = true,
}: PropsWithChildren<{
  className: string
  category: string
  isReversed?: boolean
  showIcon?: boolean
}>) {
  const { togglePopup } = useCalendly()
  return (
    <Button
      className={clsx(classes.button, className)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      category={category as any}
      onClick={togglePopup}
    >
      <span
        className={clsx(
          classes.button_caption,
          isReversed && classes.button_caption__reversed,
        )}
      >
        {children}
      </span>
      {showIcon && (
        <TbCalendarEvent
          className={clsx(
            classes.button_icon,
            isReversed && classes.button_icon__reversed,
          )}
        />
      )}
    </Button>
  )
}

function RequestPoCCalendlyWidget() {
  const { addTrackingToUrl } = useTrackingContext()

  const defaultWidgetHeight = '700px'
  const [widgetHeight, setWidgetHeight] = useState<string>(defaultWidgetHeight) // set default height

  const widgetStyles = {
    width: '100%',
    height: widgetHeight,
    minHeight: defaultWidgetHeight,
    overflow: 'hidden',
  }

  useCalendlyEventListener({
    onPageHeightResize: (event) => {
      setWidgetHeight(event.data?.payload.height) // Update height dynamically
    },
  })

  const options = {
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
  }

  return (
    <InlineWidget
      url={addTrackingToUrl(calendlyWidgetUrl)}
      pageSettings={options}
      styles={widgetStyles}
    />
  )
}
