import { createContext, useContext, useMemo, useState } from 'react'
import { CalendlyContextInterface } from './types'

const CalendlyContext = createContext<CalendlyContextInterface>({
  showPopup: false,
  togglePopup: () => {},
  eventData: null,
  setEventData: () => {},
})
CalendlyContext.displayName = 'CalendlyContext'

export const CalendlyProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [eventData, setEventData] = useState()

  const togglePopup = () => setShowPopup((v) => !v)

  const value = useMemo(
    () => ({ showPopup, togglePopup, eventData, setEventData }),
    [showPopup, togglePopup, eventData, setEventData],
  )

  return (
    <CalendlyContext.Provider value={value}>
      {children}
    </CalendlyContext.Provider>
  )
}

export function useCalendly() {
  return useContext(CalendlyContext)
}
