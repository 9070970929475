import { PropsWithChildren } from 'react'
import { Provider as ReactWrapProvider } from 'react-wrap-balancer'
import { Footer, Header } from 'routes/components/Layout'
import {
  CalendlyProvider,
  RequestPoCCalendlyPopup,
} from 'routes/components/Shared'

export type DefaultPageLayoutProps = {
  className?: string
  hideAdvertiseBlock?: boolean
}

export function DefaultPageLayout({
  className,
  hideAdvertiseBlock,
  children,
}: PropsWithChildren<DefaultPageLayoutProps>) {
  return (
    <ReactWrapProvider>
      <CalendlyProvider>
        <Header />
        <main className={className}>{children}</main>
        <Footer hideAdvertiseBlock={hideAdvertiseBlock} />
        <RequestPoCCalendlyPopup />
      </CalendlyProvider>
    </ReactWrapProvider>
  )
}
